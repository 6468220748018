import React, { useContext } from 'react';
import { FaMedapps } from "react-icons/fa";
import './css/Main.css'
import { DarkModeContext } from './DarkModeContext';


const DarkMode = () => {
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const handleSliderClick = () => {
    toggleDarkMode();
    
  };
  

  return (
      <div className='icon-container'>
      <FaMedapps size={50} className={`slider-button ${isDarkMode ? 'dark-mode' : ''}`} onClick={handleSliderClick}>
        <div className="slider"></div>
      </FaMedapps>
      </div>
      
    
  );
};

export default DarkMode;

