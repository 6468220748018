
import React, { useContext } from 'react';
import './css/Main.css';
import img from './img/img.JPG';
import { DarkModeContext } from './DarkModeContext';
import DarkMode from './Darkmode';

function Main() {

    const { isDarkMode } = useContext(DarkModeContext);

    return (

      
      <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
      <DarkMode/>

        <header className={`App-header ${isDarkMode ? 'dark-mode' : ''}`}>

          <img src={img} className="App-logo" alt="logo" />


          <div className='article-div'>
          <article className='article-start'>


          <h2 className='heading'>Intro</h2>
          <p className='writing'>
          I am Wisdom Ajayi. A Web & App Developer, Cyber Security Expert, Blockchain & Smart Contract Developer, Network engineer, Cloud solutions expert and Artist. 
          Over the years, I have honed my expertise in multiple disciplines, allowing me to tackle challenges and contribute to a wide range of projects. 
          With a strong commitment to continuous learning, I am driven to stay at the forefront of technological advancements and deliver exceptional results.
          </p>
          <br></br>
          <br></br>

          <h2 className='heading'>Skills</h2>

          <div>
          <ul>
            <li>
            Web Development: 
            <br></br>
            <br></br>
            <p className='writing'> 
            Proficient in HTML, CSS, JavaScript, and various front-end frameworks (such as React, Angular, and Vue.js). Experienced in back-end development using Node.js, Python, and PHP , etc. Skilled in developing responsive and user-friendly websites with a strong focus on performance optimization.
            </p>

            </li>



            <br></br>
            <br></br>



            <li>
            App Development: 
            <br></br>
            <br></br>
            <p className='writing'>
            Extensive experience in developing mobile applications for iOS and Android platforms using React Native and Flutter & Desktop Apps using C, C++ , Python, etc . Capable of creating intuitive and feature-rich applications that meet client requirements and deliver exceptional user experiences.
            </p>
            </li>





            <br></br>
            <br></br>




            <li>
            Data Science, Machine Learning, and Artificial Intelligence: 
            <br></br>
            <br></br>

            <p className='writing'>
            Proficient in using Python and libraries such as NumPy, Pandas, and scikit-learn for data manipulation, analysis, and machine learning model development. Skilled in applying various algorithms and techniques, including regression, classification, clustering, and deep learning, to extract insights and solve complex problems.
            </p>
            </li>





            <br></br>
            <br></br>







            <li>
            Blockchain & Smart Contract Development: 
            <br></br>
            <br></br>
            <p className='writing'>
            Proficient in developing decentralized applications (DApps) using blockchain technologies such as Ethereum ( L1 & L2) and other blockchains ( We just have to read the documentation). Experienced in creating and auditing smart contracts to ensure their reliability, security, and compliance and gas optimisation.
            I am able to write and work with smart contracts in Solidity, Vyper and Huff. 
            </p>
            </li>





            <br></br>
            <br></br>



            <li>
            Cloud Solutions: 
            <br></br>
            <br></br>
            <p className='writing'>
            Expertise in designing and deploying cloud-based solutions using platforms like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform. Familiarity with containerization technologies like Docker and orchestration tools like Kubernetes.
            </p>
            </li>





            <br></br>
            <br></br>





            <li>
            Network Engineering: 
            <br></br>
            <br></br>
            <p className='writing'>
            Strong knowledge of network protocols, routing, switching, and troubleshooting. Skilled in designing, implementing, and managing secure and scalable network infrastructures.
            </p>
            </li>

            <br></br>
            <br></br>




            <li>
            Cybersecurity: 
            
            <p className='writing'>
            Deep understanding of cybersecurity principles and best practices, including threat analysis, vulnerability assessment, penetration testing, and incident response. Skilled in implementing security measures to protect digital assets, secure networks, and mitigate risks.
            </p>

            </li>


            <br></br>
            <br></br>



            <li>
            Artistry: 
            <p className='writing'>
            Artist with a passion for creating visually captivating designs and creating generative art models. Proficient in graphic design, illustration, and digital art, utilizing tools such as Adobe Photoshop, Illustrator, to name a few.
            </p>
            </li>

            <br></br>
            <br></br>

          </ul>
          </div>

          </article>
          </div>



        </header>
      </div>
    );
  }


  export default Main;