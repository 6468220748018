import React from 'react';
import "./css/Entry.css"

function Entry() {
    return (

        <div class="article-container">
        <div class="article-title">Articles to be updated shortly</div>
        <article class="article-entry">
         
        </article>
        </div>
    );
  }
  
  export default Entry;