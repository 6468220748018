import React from 'react';
import Footer from './Footer';
import Main from './Main';
import Navbar from './Navbar';
import { DarkModeProvider } from './DarkModeContext';




function App() {
  return (
    <DarkModeProvider>
      <div>
        <Navbar />
        <Main />
        <Footer />
      </div>
    </DarkModeProvider>
  );
}

export default App;

