import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import Entry from './Article_Entries';
import { DarkModeProvider } from './DarkModeContext';

function Article() {
  return (
    <DarkModeProvider>
      <div>
        <Navbar />
        <Entry />
        <Footer />
      </div>
    </DarkModeProvider>
  );
}

export default Article;

