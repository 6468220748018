import React from 'react';
import { AiFillGithub } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import "./css/Contact.css"
import Footer from './Footer';
import Navbar from './Navbar';
import { DarkModeProvider } from './DarkModeContext';

function Contact (){

    

    return(
        <DarkModeProvider>
        <div>
        <Navbar />

        <div className="container" >
        <div class="container-item">
          <div class="title">Github</div>
          <div class="final">@WisAjayi</div>

            <h2>Random Projects</h2>

          <ul class="features">
            <li>NBA ML <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.855 20.966c-.224 0-.443-.05-.646-.146l-.104-.051-4.107-2.343-4.107 2.344-.106.053c-.488.228-1.085.174-1.521-.143-.469-.34-.701-.933-.586-1.509l.957-4.642-1.602-1.457-1.895-1.725-.078-.082c-.375-.396-.509-.97-.34-1.492.173-.524.62-.912 1.16-1.009l.102-.018 4.701-.521 1.946-4.31.06-.11c.262-.473.764-.771 1.309-.771.543 0 1.044.298 1.309.77l.06.112 1.948 4.312 4.701.521.104.017c.539.1.986.486 1.158 1.012.17.521.035 1.098-.34 1.494l-.078.078-3.498 3.184.957 4.632c.113.587-.118 1.178-.59 1.519-.252.182-.556.281-.874.281zm-8.149-6.564c-.039.182-.466 2.246-.845 4.082l3.643-2.077c.307-.175.684-.175.99 0l3.643 2.075-.849-4.104c-.071-.346.045-.705.308-.942l3.1-2.822-4.168-.461c-.351-.039-.654-.26-.801-.584l-1.728-3.821-1.726 3.821c-.146.322-.45.543-.801.584l-4.168.461 3.1 2.822c.272.246.384.617.302.966z"></path></svg></span>  </li>
            <li>Music ML <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.855 20.966c-.224 0-.443-.05-.646-.146l-.104-.051-4.107-2.343-4.107 2.344-.106.053c-.488.228-1.085.174-1.521-.143-.469-.34-.701-.933-.586-1.509l.957-4.642-1.602-1.457-1.895-1.725-.078-.082c-.375-.396-.509-.97-.34-1.492.173-.524.62-.912 1.16-1.009l.102-.018 4.701-.521 1.946-4.31.06-.11c.262-.473.764-.771 1.309-.771.543 0 1.044.298 1.309.77l.06.112 1.948 4.312 4.701.521.104.017c.539.1.986.486 1.158 1.012.17.521.035 1.098-.34 1.494l-.078.078-3.498 3.184.957 4.632c.113.587-.118 1.178-.59 1.519-.252.182-.556.281-.874.281zm-8.149-6.564c-.039.182-.466 2.246-.845 4.082l3.643-2.077c.307-.175.684-.175.99 0l3.643 2.075-.849-4.104c-.071-.346.045-.705.308-.942l3.1-2.822-4.168-.461c-.351-.039-.654-.26-.801-.584l-1.728-3.821-1.726 3.821c-.146.322-.45.543-.801.584l-4.168.461 3.1 2.822c.272.246.384.617.302.966z"></path></svg></span></li>
          </ul>
          
          <a href='https://github.com/WisAjayi' target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
          <AiFillGithub size={20} color={'black'} />
          </a>


        </div>


        <div class="container-item">
          <div class="title">Mail</div>
          <h3 >wisdomajayi@protonmail.com</h3>
         
        </div>


        <div class="container-item">
          <div class="title">Twitter</div>
          <div class="final">@TBA</div>

          <a href='https://twitter.com/' target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
          <BsTwitter size={20} color={'black'} />
          </a>


        </div>

      </div>

      <Footer />
      </div>
    </DarkModeProvider>
    );
};

export default Contact;