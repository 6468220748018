
import React, { useContext,useRef } from 'react';
import "./css/Main.css"
import { AiFillGithub } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import { BsTwitter } from "react-icons/bs";
import { DarkModeContext } from './DarkModeContext';



function Footer() {

    const { isDarkMode } = useContext(DarkModeContext);

    const formRef = useRef(null);

    const handleIconClick = () => {
      // Check if the form reference is available
      if (formRef.current) {
        // Submit the form programmatically
        formRef.current.submit();
      }
    };

    return(
        <div className={`div-container ${isDarkMode ? 'dark-mode' : ''}`}>

        <div className="footer-div">
            <p className="">Copyright © 2023 🥷</p>
        </div>

        <div className="footer-second">
            <p>Find me on </p>
            <ul className="footer-list">
                <li><a href="https://github.com/WisAjayi" target="_blank" rel="noreferrer"> <AiFillGithub size={20} color={isDarkMode ? 'green' : 'black'} />  </a></li>

                <form ref={formRef} action='mailto:@wisdomajayi@protonmail.com' method='post' encType='text/plain'>
                <li> <i  target="_blank" rel="noreferrer" onClick={handleIconClick}>  <IoMail size={20} color={isDarkMode ? 'green' : 'black'} /> </i> </li>
               
                </form>
                <li> <a href='https://' target="_blank" rel="noreferrer"> <BsTwitter size={20} color={isDarkMode ? 'green' : 'black'}/> </a> </li>
            </ul>
        </div>

        </div>

    );
};

export default  Footer;