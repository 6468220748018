import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/Navbar.css';


const Navbar = () => {
const [isSticky, setIsSticky] = useState(false);


useEffect(() => {
  const handleScroll = () => {
    const isTop = window.scrollY < 0;
    setIsSticky(!isTop);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);



  return (
   
    <nav className={`${isSticky ? 'navbar sticky' : 'navbar'}`}>

      <div className="logo">
        <span className="logo-text">Wisdom Ajayi's Website</span>
      </div>
      <ul className="nav-menu">
        <li className="nav-item">
          <Link to="/" className="nav-link">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/contacts" target='_blank' rel="noreferrer" className="nav-link">Contacts</Link>
        </li>
        <li className="nav-item">
          <Link to="/articles" target='_blank' rel="noreferrer" className="nav-link">Articles</Link>
        </li>
      </ul>
    </nav>




   
  );
};


export default Navbar;
